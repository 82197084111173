import {FC} from 'react';
import {
    Wrapper
} from 'modules/theme/components/listing/cards/advertisementCard/components/card/partials/card/photoPart/preview/partials/badges/partials/wrapper/Wrapper';
import {
    TopBadges
} from 'modules/theme/components/listing/cards/advertisementCard/components/card/partials/card/photoPart/preview/partials/badges/partials/top/TopBadges';
import {
    BottomBadges
} from 'modules/theme/components/listing/cards/advertisementCard/components/card/partials/card/photoPart/preview/partials/badges/partials/bottom/BottomBadges';

interface IBadges {
    isTop?: boolean;
    has3d?: boolean;
    isBasic?: boolean;
    hasVideo?: boolean;
    isPremium?: boolean;
    isDevProject?: boolean;
    hasFloowPlan?: boolean;
    isAgencyProfi?: boolean;
    isFavorite?: boolean;
    isInComparison?: boolean;
    isFavoriteDisabled?: boolean;
    isComparisonDisabled?: boolean; 
    onCompareClick?: () => void;
    onFavoriteClick?: () => void;
}

export const Badges: FC<IBadges> = ({
    isTop,
    has3d,
    isBasic,
    hasVideo,
    isPremium,
    isDevProject,
    hasFloowPlan,
    isAgencyProfi,
    isFavorite,
    isInComparison,
    isFavoriteDisabled,
    isComparisonDisabled,
    onCompareClick,
    onFavoriteClick,
}) => {

    return <Wrapper>

        <TopBadges
            isTop={isTop}
            isBasic={isBasic}
            isPremium={isPremium}
            isFavorite={isFavorite}
            isInComparison={isInComparison}
            isFavoriteDisabled={isFavoriteDisabled}
            isComparisonDisabled={isComparisonDisabled}
            onFavoriteClick={onFavoriteClick}
            onCompareClick={onCompareClick}
        />

        <BottomBadges
            has3d={has3d}
            hasVideo={hasVideo}
            isDevProject={isDevProject}
            hasFloowPlan={hasFloowPlan}
            isAgencyProfi={isAgencyProfi}
        />

    </Wrapper>;
};
