import { FC } from 'react';
import { Box, useTheme } from '@mui/material';
import { Svg } from 'modules/theme/components/svg/Svg';
import HeartIcon from 'modules/theme/components/icons/basic/Heart.svg';
import HeartOutlineIcon from 'modules/theme/components/icons/basic/HeartOutline.svg';
import FavoriteIcon from 'modules/theme/components/icons/basic/Favourite.svg';
import FavoriteActiveIcon from 'modules/theme/components/icons/basic/FavouriteActive.svg';

interface IFavorite {
    isActive: boolean;
    onClick: () => void;
    isDisabled?: boolean;
    isListingCard?: boolean;
}

export const Favorite: FC<IFavorite> = ({ isActive, isDisabled, isListingCard, onClick }) => {
    const { palette } = useTheme();

    const icon = isListingCard
        ? isActive
            ? FavoriteActiveIcon
            : FavoriteIcon
        : isActive
            ? HeartIcon
            : HeartOutlineIcon;

    const onIconClick = (e) => {
        if (isDisabled) return;
        onClick();
        e.stopPropagation();
        e.preventDefault();
    };

    return (
        <Box
            onClick={onIconClick}
            sx={{
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
            }}
        >
            <Svg
                width={isListingCard ? 29: 24}
                component={icon}
                sx={{
                    WebkitFilter: 'drop-shadow(0px 0px 6px rgb(0 0 0 / 0.2))',
                    Filter: 'drop-shadow(0px 0px 6px rgb(0 0 0 / 0.2))',
                    '& path': {
                        fill: (!isListingCard && isActive) && palette.identityBasicRed.main,
                    },
                }}
            />
        </Box>
    );
};
