import {FC} from 'react';
import {Box, Stack} from '@mui/material';
import {
    AttachmentsBadges
} from 'modules/theme/components/listing/cards/advertisementCard/components/card/partials/card/photoPart/preview/partials/badges/partials/bottom/partials/attachments/AttachmentsBadges';
import {
    DevProjectBadge
} from 'modules/theme/components/listing/cards/advertisementCard/components/card/partials/card/photoPart/preview/partials/badges/partials/bottom/partials/DevProjectBadge';
import {
    ProfiBadge
} from 'modules/theme/components/listing/cards/advertisementCard/components/card/partials/card/photoPart/preview/partials/badges/partials/bottom/partials/ProfiBadge';

interface IBottomBadges {
    has3d?: boolean;
    hasVideo?: boolean;
    isDevProject?: boolean;
    hasFloowPlan?: boolean;
    isAgencyProfi?: boolean;
}

export const BottomBadges: FC<IBottomBadges> = ({has3d, hasVideo, isDevProject, hasFloowPlan, isAgencyProfi}) => {

    return <Box display="flex" justifyContent="space-between" alignItems="end">
        <Box>
            <AttachmentsBadges has3d={has3d} hasVideo={hasVideo} hasFloowPlan={hasFloowPlan}/>
        </Box>

        <Stack direction="row" gap={.5} height={40}>
            {isDevProject && <DevProjectBadge/>}
            {isAgencyProfi && <ProfiBadge/>}
        </Stack>
    </Box>;
};
