import {FC} from 'react';
import {Box, Theme, useMediaQuery} from '@mui/material';
import {AgentPhoto as Photo} from 'modules/theme/components/agency/agent/common/components/AgentPhoto';

interface ISellerPhoto {
    isPro?: boolean;
    photoUrl?: string;
}

export const SellerPhoto: FC<ISellerPhoto> = ({photoUrl, isPro}) => {
    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

    return <Box>
        <Photo
            width={48}
            isPro={isPro}
            badgeLeft={isMobile}
            previewPhotoUrl={photoUrl}
        />
    </Box>;
};
