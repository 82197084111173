import {FC} from 'react';
import {Svg} from 'modules/theme/components/svg/Svg';
import {Text} from 'modules/theme/components/text/Text';
import MappinIcon from 'modules/theme/components/icons/basic/Mappin.svg';
import {
    ParameterWrapper
} from 'modules/theme/components/listing/cards/common/components/parameters/common/ParameterWrapper';

interface ILocation {
    location: string;
}

export const Location: FC<ILocation> = ({location}) => {

    return <ParameterWrapper>
        <Svg icon component={MappinIcon} width={16}/>
        <Text variant="body3" noWrap>{location}</Text>
    </ParameterWrapper>;
};
