import { FC } from 'react';
import { Box } from '@mui/material';
import Image from 'components/image/Image';
import DevProject from 'modules/theme/components/assets/badges/svg/DevProject.svg?url';

export const DevProjectBadge: FC = () => {
    return (
        <Box>
            <Image alt="" width={40} height={40} src={DevProject} />
        </Box>
    );
};
