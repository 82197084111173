import {FC} from 'react';
import {Stack} from '@mui/material';
import {
    PackageBadges
} from 'modules/theme/components/listing/cards/advertisementCard/components/card/partials/card/photoPart/preview/partials/badges/partials/top/partials/PackageBadges';
import {
    InteractionBadges
} from 'modules/theme/components/listing/cards/advertisementCard/components/card/partials/card/photoPart/preview/partials/badges/partials/top/partials/InteractionBadges';

interface ITopBadges {
    isTop?: boolean;
    isBasic?: boolean;
    isPremium?: boolean;
    isFavorite?: boolean;
    isInComparison?: boolean;
    isFavoriteDisabled?: boolean;
    isComparisonDisabled?: boolean;
    onCompareClick?: () => void;
    onFavoriteClick?: () => void;
}

export const TopBadges: FC<ITopBadges> = ({
    isTop,
    isBasic,
    isPremium,
    isFavorite,
    isInComparison,
    isFavoriteDisabled,
    isComparisonDisabled,
    onFavoriteClick,
    onCompareClick,
}) => {
    return <Stack direction="row" justifyContent="space-between" alignItems="center">
        <PackageBadges isTop={isTop} isBasic={isBasic} isPremium={isPremium}/>
        <InteractionBadges
            isFavorite={isFavorite}
            isInComparison={isInComparison}
            isFavoriteDisabled={isFavoriteDisabled}
            isComparisonDisabled={isComparisonDisabled}
            onFavoriteClick={onFavoriteClick}
            onCompareClick={onCompareClick}
        />
    </Stack>;
};
