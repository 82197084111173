import {FC} from 'react';
import {PhotoItem} from 'modules/theme/components/listing/cards/common/components/photoItem/PhotoItem';
import { blurDataURL } from 'modules/theme/components/listing/cards/common/components/constants/blurDataURL';

interface IThumbPhoto {
    photoUrl?: string;
    priorityLoad?: boolean;
}

export const ThumbPhoto: FC<IThumbPhoto> = ({photoUrl, priorityLoad}) => {
    return <PhotoItem photoUrl={photoUrl} height={88} width={120} priorityLoad={priorityLoad} blurDataURL={blurDataURL}/>;
};
