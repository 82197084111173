import {FC, ReactNode} from 'react';
import Link from 'next/link';

interface ILinkWrapper {
    children: ReactNode;
    detailUrl: string;
    onClick?: () => void;
    openInNewTab?: boolean;
}

export const LinkWrapper: FC<ILinkWrapper> = ({detailUrl, onClick, children, openInNewTab}) => {

    return <Link
        prefetch={false}
        onClick={onClick}
        href={detailUrl}
        style={{
            zIndex: 1,
            color: 'inherit',
            textDecoration: 'none'
        }}
        target={openInNewTab && '_blank'}
    >
        {children}
    </Link>;
};
