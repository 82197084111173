import {FC} from 'react';
import {Palette, Stack} from '@mui/material';
import {Text} from 'modules/theme/components/text/Text';

interface IPrice {
    price: string;
    isMobile?: boolean;
    detailUrl?: string;
    unitPrice?: string;
    onClick?: () => void;
}

export const Price: FC<IPrice> = ({price, unitPrice, detailUrl, onClick, isMobile}) => {
    return <Stack
        href={detailUrl}
        onClick={onClick}
        gap={{xs: 1, md: 0}}
        target={!isMobile && '_blank'}
        component={detailUrl ? 'a' : 'div'}
        direction={{xs: 'row', md: 'column'}}
        alignItems={{xs: 'baseline', md: 'end'}}
        sx={{
            flexShrink: 0,
            color: 'inherit',
            textDecoration: 'none',
            '&:hover': {
                textDecoration: 'none',
            },
        }}
    >
        <Text variant="h4" mobileVariant="h5" semibold>
            {price}
        </Text>

        {unitPrice &&
            <Text variant="label1" color={(palette: Palette) => palette.labelSecondary}>
                {unitPrice}
            </Text>
        }
    </Stack>;
};
