import {FC} from 'react';
import {
    Content as DesktopContent
} from 'modules/theme/components/listing/cards/advertisementCard/components/card/partials/card/contentPart/desktop/Content';
import {
    Content as MobileContent
} from 'modules/theme/components/listing/cards/advertisementCard/components/card/partials/card/contentPart/mobile/Content';
import { Desktop, Mobile } from 'modules/theme/components/responsive';
import {
    ESubcategory
} from 'modules/theme/components/listing/cards/advertisementCard/enums/parameters/category/ESubcategory';
import {
    IListingUrl
} from 'modules/theme/components/listing/cards/advertisementCard/interfaces/advertisement/partials/listingUrl/IListingUrl';
import {
    IDevProject
} from 'modules/theme/components/listing/cards/advertisementCard/interfaces/advertisement/partials/devProject/IDevProject';
interface IContentPart {
    title: string;
    price: string;
    area?: number;
    location: string;
    detailUrl: string;
    unitPrice?: string;
    onClick: () => void;
    nameOfAdvertiser: string;
    description: string;
    isPremium?: boolean;
    isSellerPro?: boolean;
    isAgencyProfi?: boolean;
    sellerPhotoUrl?: string;
    listingUrl?: IListingUrl;
    devProject?: IDevProject;
    subcategory: ESubcategory;
}

export const ContentPart: FC<IContentPart> = ({
    area,
    title,
    price,
    onClick,
    location,
    unitPrice,
    isPremium,
    detailUrl,
    devProject,
    listingUrl,
    nameOfAdvertiser,
    description,
    isSellerPro,
    subcategory,
    isAgencyProfi,
    sellerPhotoUrl,
}) => {
    return <>
        <Mobile>
            <MobileContent
                area={area}
                price={price}
                onClick={onClick}
                location={location}
                unitPrice={unitPrice}
                detailUrl={detailUrl}
                devProject={devProject}
                subcategory={subcategory}
                isSellerPro={isSellerPro}
                sellerPhotoUrl={sellerPhotoUrl}
            />
        </Mobile>
        <Desktop>
            <DesktopContent
                area={area}
                title={title}
                price={price}
                onClick={onClick}
                location={location}
                detailUrl={detailUrl}
                unitPrice={unitPrice}
                isPremium={isPremium}
                nameOfAdvertiser={nameOfAdvertiser}
                listingUrl={listingUrl}
                devProject={devProject}
                description={description}
                subcategory={subcategory}
                isSellerPro={isSellerPro}
                isAgencyProfi={isAgencyProfi}
                sellerPhotoUrl={sellerPhotoUrl}
            />
        </Desktop>
    </>;
};
