import React, {FC} from 'react';
import {Stack, Box} from '@mui/material';
import {ESubcategory} from 'modules/theme/components/listing/cards/advertisementCard/enums/parameters/category/ESubcategory';
import {
    Title
} from 'modules/theme/components/listing/cards/advertisementCard/components/card/partials/card/contentPart/common/title/Title';
import {
    Information
} from 'modules/theme/components/listing/cards/advertisementCard/components/card/partials/card/contentPart/common/information/Information';
import {
    Description
} from 'modules/theme/components/listing/cards/advertisementCard/components/card/partials/card/contentPart/common/description/Description';
import {
    Price
} from 'modules/theme/components/listing/cards/advertisementCard/components/card/partials/card/contentPart/common/price/Price';
import {
    SellerPhoto
} from 'modules/theme/components/listing/cards/advertisementCard/components/card/partials/card/contentPart/common/seller/SellerPhoto';
import {
    ListingLink
} from 'modules/theme/components/listing/cards/advertisementCard/components/card/partials/card/contentPart/common/listingLink/ListingLink';
import {
    NameOfAdvertiser
} from 'modules/theme/components/listing/cards/advertisementCard/components/card/partials/card/contentPart/common/agencyName/NameOfAdvertiser';
import {
    IListingUrl
} from 'modules/theme/components/listing/cards/advertisementCard/interfaces/advertisement/partials/listingUrl/IListingUrl';
import {
    IDevProject
} from 'modules/theme/components/listing/cards/advertisementCard/interfaces/advertisement/partials/devProject/IDevProject';
import {
    DevProjectLink
} from 'modules/theme/components/listing/cards/advertisementCard/components/card/partials/card/contentPart/common/devProject/DevProjectLink';

interface IContent {
    title: string;
    price: string;
    area?: number;
    isPro?: boolean;
    location: string;
    detailUrl: string;
    onClick: () => void;
    nameOfAdvertiser: string;
    unitPrice?: string;
    isPremium?: boolean;
    description: string;
    isSellerPro?: boolean;
    isAgencyProfi?: boolean;
    listingUrl?: IListingUrl;
    sellerPhotoUrl?: string;
    devProject?: IDevProject;
    subcategory: ESubcategory;
}

export const Content: FC<IContent> = ({
    title,
    area,
    price,
    onClick,
    location,
    detailUrl,
    isPremium,
    unitPrice,
    devProject,
    listingUrl,
    nameOfAdvertiser,
    subcategory,
    isSellerPro,
    description,
    isAgencyProfi,
    sellerPhotoUrl,
}) => {
    return <Stack
        paddingY={1}
        height="100%"
        direction="column"
        position="relative"
        justifyContent="space-between"
    >
        <Box
            top={0}
            left={0}
            width="100%"
            component="a"
            target="_blank"
            href={detailUrl}
            onClick={onClick}
            position="absolute"
            height={devProject
                ? 'calc(100% - 80px)'
                : 'calc(100% - 48px)'
            }
        />

        <Stack
            gap={1}
            onClick={onClick}
            direction="column"
        >
            <Title title={title} isHighlighted={isAgencyProfi}/>

            <Information area={area} location={location} subcategory={subcategory}/>

            <Description description={description} showMoreText={isPremium}/>
        </Stack>

        <Stack direction="row">
            {devProject && <DevProjectLink devProject={devProject}/>}
        </Stack>

        <Stack direction="row" justifyContent="space-between" alignItems="center">

            <Stack direction="row" alignItems="end" gap={1.5} flexShrink={0}>
                <SellerPhoto photoUrl={sellerPhotoUrl} isPro={isSellerPro}/>
                <Box
                    height={35}
                    display='flex'
                    flexDirection='column'
                    justifyContent={listingUrl
                        ? 'center'
                        : 'flex-start'
                    }
                >
                    <NameOfAdvertiser nameOfAdvertiser={nameOfAdvertiser} />
                    {listingUrl && <ListingLink url={listingUrl.url} text={listingUrl.text}/>}
                </Box>
            </Stack>

            <Box component="a" href={detailUrl} width="100%" height="100%" target="_blank"/>

            <Price
                price={price}
                onClick={onClick}
                unitPrice={unitPrice}
                detailUrl={detailUrl}
            />
        </Stack>

    </Stack>;
};
