export const createSeoString = (inputString: string): string => {
    return inputString
        .toLowerCase()
        .replace(/\s+/g, '-')
        .replace(/[áäâà]/g, 'a')
        .replace(/[éěĕëèê]/g, 'e')
        .replace(/[íïîì]/g, 'i')
        .replace(/[óôöòõ]/g, 'o')
        .replace(/[úůûüù]/g, 'u')
        .replace(/[ýÿ]/g, 'y')
        .replace(/[čćċ]/g, 'c')
        .replace(/[šś]/g, 's')
        .replace(/[źžż]/g, 'z')
        .replace(/[ľ,ĺ]/g, 'l')
        .replace(/[ñ,ň]/g, 'n')
        .replace(/[ť,ț]/g, 't')
        .replace(/[ď,ð]/g, 'd')
        .replace(/ô/g, 'o')
        .replace(/ä/g, 'a')
        .replace(/[^a-z\d-]/g, '');
};
