import {FunctionComponent, SVGProps} from 'react';
import Room1Icon from 'modules/theme/components/icons/category/Room1.svg';
import Room2Icon from 'modules/theme/components/icons/category/Room2.svg';
import Room3Icon from 'modules/theme/components/icons/category/Room3.svg';
import Room4Icon from 'modules/theme/components/icons/category/Room4.svg';
import Room5Icon from 'modules/theme/components/icons/category/Room5.svg';
import StudioIcon from 'modules/theme/components/icons/category/Studio.svg';
import LandOutlineIcon from 'modules/theme/components/icons/category/LandOutline.svg';
import HouseOutlineIcon from 'modules/theme/components/icons/category/HouseOutline.svg';
import CabinOutlineIcon from 'modules/theme/components/icons/category/CabinOutline.svg';
import SpaceOutlineIcon from 'modules/theme/components/icons/category/SpaceOutline.svg';
import ObjectOutlineIcon from 'modules/theme/components/icons/category/ObjectOutline.svg';
import {ESubcategory} from 'modules/theme/components/listing/cards/advertisementCard/enums/parameters/category/ESubcategory';


export const subcategoryIcon: Record<ESubcategory, FunctionComponent<SVGProps<SVGSVGElement>>> = {
    [ESubcategory.STUDIO_APARTMENT]: StudioIcon,
    [ESubcategory.DOUBLE_STUDIO_APARTMENT]: StudioIcon,
    [ESubcategory.ONE_ROOM_APARTMENT]: Room1Icon,
    [ESubcategory.TWO_ROOM_APARTMENT]: Room2Icon,
    [ESubcategory.THREE_ROOM_APARTMENT]: Room3Icon,
    [ESubcategory.FOUR_ROOM_APARTMENT]: Room4Icon,
    [ESubcategory.FIVE_PLUS_ROOM_APARTMENT]: Room5Icon,
    [ESubcategory.HOLIDAY_APARTMENT]: Room5Icon,
    [ESubcategory.LOFT]: Room5Icon,
    [ESubcategory.MAISONETTE]: Room5Icon,
    [ESubcategory.OTHER_TYPE_OF_APARTMENT]: Room5Icon,

    [ESubcategory.FAMILY_HOUSE]: HouseOutlineIcon,
    [ESubcategory.COUNTRY_HOUSE]: HouseOutlineIcon,
    [ESubcategory.MOBILE_HOUSE]: HouseOutlineIcon,
    [ESubcategory.HOUSEBOAT]: HouseOutlineIcon,
    [ESubcategory.OTHER_OBJECT_FOR_HOUSING]: HouseOutlineIcon,
    [ESubcategory.FARM_SETTLEMENT]: HouseOutlineIcon,

    [ESubcategory.CABIN_AND_LOG_CABIN]: CabinOutlineIcon,
    [ESubcategory.COTTAGE_AND_RECREATION_HOUSE]: CabinOutlineIcon,
    [ESubcategory.GARDEN_HUT]: CabinOutlineIcon,
    [ESubcategory.OTHER_RECREATIONAL_OBJECT]: CabinOutlineIcon,

    [ESubcategory.LAND_FOR_FAMILY_HOUSE]: LandOutlineIcon,
    [ESubcategory.RECREATIONAL_LAND]: LandOutlineIcon,
    [ESubcategory.LAND_FOR_HOUSING_CONSTRUCTION]: LandOutlineIcon,
    [ESubcategory.LAND_FOR_CIVIC_AMENITIES]: LandOutlineIcon,
    [ESubcategory.COMMERCIAL_ZONE]: LandOutlineIcon,
    [ESubcategory.INDUSTRIAL_ZONE]: LandOutlineIcon,
    [ESubcategory.MIXED_ZONE]: LandOutlineIcon,
    [ESubcategory.GARDEN]: LandOutlineIcon,
    [ESubcategory.ORCHARD]: LandOutlineIcon,
    [ESubcategory.VINEYARD_AND_HOP_GARDEN]: LandOutlineIcon,
    [ESubcategory.MEADOW_AND_PASTURE]: LandOutlineIcon,
    [ESubcategory.ARABLE_LAND]: LandOutlineIcon,
    [ESubcategory.FOREST_LAND]: LandOutlineIcon,
    [ESubcategory.POND_AND_LAKE]: LandOutlineIcon,
    [ESubcategory.LAND_FOR_ADVERTISING]: LandOutlineIcon,
    [ESubcategory.OTHER_TYPE_OF_LAND]: LandOutlineIcon,
    [ESubcategory.OTHER_LAND_TO_BUILDING]: LandOutlineIcon,
    [ESubcategory.OTHER_AGRICULTURAL_LAND]: LandOutlineIcon,
    [ESubcategory.SECONDARY_AGRICULTURAL_AND_FOREST_LAND]: LandOutlineIcon,
    [ESubcategory.SECONDARY_RECREATIONAL_LAND]: LandOutlineIcon,
    [ESubcategory.SECONDARY_COMMERCIAL_PLOTS]: LandOutlineIcon,
    [ESubcategory.SECONDARY_ORCHARDS_VINEYARDS_HOP_FIELDS]: LandOutlineIcon,

    [ESubcategory.OFFICES]: SpaceOutlineIcon,
    [ESubcategory.BUSINESS_SPACES]: SpaceOutlineIcon,
    [ESubcategory.RESTAURANT_SPACES]: SpaceOutlineIcon,
    [ESubcategory.STORAGE_AREAS]: SpaceOutlineIcon,
    [ESubcategory.SPACE_FOR_PRODUCTION]: SpaceOutlineIcon,
    [ESubcategory.REPAIR_AREA]: SpaceOutlineIcon,
    [ESubcategory.SPORTS_FACILITIES]: SpaceOutlineIcon,
    [ESubcategory.SPACE_FOR_ADVERTISEMENT]: SpaceOutlineIcon,
    [ESubcategory.OTHER_TYPE_OF_SPACE]: SpaceOutlineIcon,
    [ESubcategory.SECONDARY_ANOTHER_SPACE_AND_OBJECT]: SpaceOutlineIcon,

    [ESubcategory.APARTMENT_HOUSE]: ObjectOutlineIcon,
    [ESubcategory.RENTAL_HOUSE]: ObjectOutlineIcon,
    [ESubcategory.OFFICE_BUILDING]: ObjectOutlineIcon,
    [ESubcategory.COMMERCIAL_OBJECT]: ObjectOutlineIcon,
    [ESubcategory.RESTAURANT]: ObjectOutlineIcon,
    [ESubcategory.POLYFUNCTIONAL_BUILDING]: ObjectOutlineIcon,
    [ESubcategory.WAREHOUSE]: ObjectOutlineIcon,
    [ESubcategory.MANUFACTURING_FACILITY]: ObjectOutlineIcon,
    [ESubcategory.REPAIR_FACILITY]: ObjectOutlineIcon,
    [ESubcategory.OBJECT_FOR_BREEDING_ANIMALS]: ObjectOutlineIcon,
    [ESubcategory.HOTEL_AND_PENSION]: ObjectOutlineIcon,
    [ESubcategory.SPA]: ObjectOutlineIcon,
    [ESubcategory.HISTORICAL_OBJECT]: ObjectOutlineIcon,
    [ESubcategory.COMMERCIAL_FACILITY]: ObjectOutlineIcon,
    [ESubcategory.SPORT_OBJECT]: ObjectOutlineIcon,
    [ESubcategory.SMALL_POWER_STATION]: ObjectOutlineIcon,
    [ESubcategory.GAS_STATION]: ObjectOutlineIcon,
    [ESubcategory.MOBILE_CELLS_AND_STANDS]: ObjectOutlineIcon,
    [ESubcategory.OTHER_TYPE_OF_OBJECT]: ObjectOutlineIcon,
    [ESubcategory.DETACHED_GARAGE]: ObjectOutlineIcon,
    [ESubcategory.CONSOLIDATED_GROUND]: ObjectOutlineIcon,
    [ESubcategory.OTHER_AGRICULTURAL_OBJECT]: ObjectOutlineIcon,
    [ESubcategory.SECONDARY_GARAGE_AND_PARKING]: ObjectOutlineIcon,
    [ESubcategory.SECONDARY_PRODUCTION]: ObjectOutlineIcon,
    [ESubcategory.SECONDARY_THE_SHOP]: ObjectOutlineIcon,
    [ESubcategory.SECONDARY_WAREHOUSES]: ObjectOutlineIcon,
    [ESubcategory.SECONDARY_HOTEL_GUESTHOUSE]: ObjectOutlineIcon,
    [ESubcategory.SECONDARY_OFFICES_AND_ADMINISTRATION]: ObjectOutlineIcon,
};
