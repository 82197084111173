import {FC} from 'react';
import Link from 'components/link/Link';
import {Text} from 'modules/theme/components/text/Text';
import {Stack} from '@mui/material';
import {
    IDevProject as DsIDevProject
} from 'modules/theme/components/listing/cards/advertisementCard/interfaces/advertisement/partials/devProject/IDevProject';

interface IDevProjectLink {
    devProject?: DsIDevProject;
}

export const DevProjectLink: FC<IDevProjectLink> = ({devProject}) => {
    return <Link href={devProject.url} openInNewTab>
        <Stack
            p={1}
            direction="row"
            borderRadius={1}
            bgcolor={theme => theme.palette.backgroundSecondary.main}
        >
            <Text variant="label2">
                Developerský projekt:&nbsp;
            </Text>
            <Text variant="label2" semibold>
                {devProject.name}
            </Text>
        </Stack>
    </Link>;
};