import {FC} from 'react';
import {OverridableStringUnion} from '@mui/types';
import {Variant} from '@mui/material/styles/createTypography';
import {TypographyPropsVariantOverrides} from '@mui/material/Typography/Typography';
import {
    ESubcategory
} from 'modules/theme/components/listing/cards/advertisementCard/enums/parameters/category/ESubcategory';

import {
    Parameter
} from 'modules/theme/components/listing/cards/common/components/parameters/common/Parameter';
import {
    subcategoryIcon
} from 'modules/theme/components/listing/cards/advertisementCard/constants/parameters/category/subcategoryIcon';

interface ISubcategory {
    subcategory: ESubcategory,
    variant: OverridableStringUnion<Variant, TypographyPropsVariantOverrides>;
}

export const Subcategory: FC<ISubcategory> = ({subcategory, variant}) => {
    return  <Parameter variant={variant} value={subcategory} icon={subcategoryIcon[subcategory]} noWrap/>;
};
