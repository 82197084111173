import { FC } from 'react';
import { Box, useTheme } from '@mui/material';
import { Svg } from 'modules/theme/components/svg/Svg';
import WeightsIcon from 'modules/theme/components/icons/basic/Weights.svg';
import ComparisonIcon from 'modules/theme/components/icons/basic/Comparison.svg';
import ComparisonActiveIcon from 'modules/theme/components/icons/basic/ComparisonActive.svg';

interface ICompare {
    isActive: boolean;
    onClick: () => void;
    isDisabled?: boolean;
    isListingCard?: boolean;
}

export const Compare: FC<ICompare> = ({ isActive, isDisabled, onClick, isListingCard }) => {
    const { palette } = useTheme();

    const icon = isListingCard
        ? isActive
            ? ComparisonActiveIcon
            : ComparisonIcon
        : isActive
            ? WeightsIcon
            : WeightsIcon;

    const onIconClick = (e) => {
        if (isDisabled) return;
        onClick();
        e.stopPropagation();
        e.preventDefault();
    };

    return (
        <Box
            onClick={onIconClick}
            sx={{
                display: 'flex',
                cursor: 'pointer',
                alignItems: 'center',
            }}
        >
            <Svg
                width={isListingCard ? 29: 24}
                component={icon}
                sx={{
                    '& path': {
                        fill: (!isListingCard && isActive) && palette.identityBasicRed.main,
                    },
                }}
            />{' '}
        </Box>
    );
};
