import {FC, ReactNode} from 'react';
import {Box, useTheme} from '@mui/material';

interface IAttachmentWrapper {
    children: ReactNode;
}

export const AttachmentWrapper: FC<IAttachmentWrapper> = ({children}) => {
    const {palette} = useTheme();

    return <Box
        width={26}
        height={26}
        display="flex"
        borderRadius={24}
        alignItems="center"
        justifyContent="center"
        bgcolor={palette.backgroundPrimary.main}
        boxShadow={'0px 2px 4px 0px ' + palette.backgroundPrimary.main + 80}
    >
        {children}

    </Box>;
};
