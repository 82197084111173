import { FC } from 'react';
import { Text } from 'modules/theme/components/text/Text';

interface ITitle {
    title: string;
    isHighlighted?: boolean;
}

export const Title: FC<ITitle> = ({ title, isHighlighted }) => {
    return (
        <Text
            noWrap
            semibold
            variant="h4"
            component="h2"
            color={(palette) =>
                isHighlighted ? palette.primary : palette.labelPrimary
            }
        >
            {title}
        </Text>
    );
};
