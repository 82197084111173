import {FC} from 'react';
import {Palette} from '@mui/material';
import Link from 'components/link/Link';
import {Text} from 'modules/theme/components/text/Text';


interface IListingLink {
    text: string;
    url: string;
}

export const ListingLink: FC<IListingLink> = ({text, url}) => {
    return <Link href={url}>
        <Text variant="breadcrumb" color={(palette: Palette) => palette.labelSecondary}>
            {text}
        </Text>
    </Link>;
};
