import { FC } from 'react';
import { Stack } from '@mui/material';
import { Compare } from 'modules/theme/components/listing/compare/Compare';
import { Favorite } from 'modules/theme/components/listing/favorite/Favorite';

interface IInteractionBadges {
    isFavorite?: boolean;
    isInComparison?: boolean;
    onCompareClick?: () => void;
    onFavoriteClick?: () => void;
    isFavoriteDisabled?: boolean;
    isComparisonDisabled?: boolean;
}

export const InteractionBadges: FC<IInteractionBadges> = ({
    isFavorite,
    isInComparison,
    onCompareClick,
    onFavoriteClick,
    isFavoriteDisabled,
    isComparisonDisabled
}) => {
    return <Stack direction="row">
        {onFavoriteClick && <Favorite isActive={isFavorite} isDisabled={isFavoriteDisabled} onClick={onFavoriteClick} isListingCard />}
        {onCompareClick && <Compare isActive={isInComparison} isDisabled={isComparisonDisabled} onClick={onCompareClick} isListingCard />}
    </Stack>;
};
